<template>
  <section id="template-email">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card>
          <b-row
            class="header-card"
            align-v="center"
          >
            <b-col>
              <h4>
                Resultados
              </h4>
            </b-col>
            <b-col class="text-right">
              <b-button
                variant="primary"
                :to="{ name: 'tools-email-template-form-insert' }"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                  class="align-middle"
                />
                Adicionar Template
              </b-button>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-overlay
                :show="isRemoving"
                rounded="sm"
              >
                <b-table
                  :busy="isLoading"
                  responsive
                  :items="results"
                  :fields="result_fields"
                  show-empty
                  sort-icon-left
                  no-local-sorting
                  :sort-by.sync="filtersValues.sortBy"
                  :sort-desc.sync="filtersValues.sortDesc"
                  @sort-changed="sortingChanged"
                >
                  <template #empty>
                    <h4 class="text-center mt-2 mb-2">
                      Nenhum resultado encontrado
                    </h4>
                  </template>

                  <template #table-busy>
                    <div class="text-center table-spinner">
                      <b-spinner label="Loading..." />
                      <strong>Carregando...</strong>
                    </div>
                  </template>

                  <template #head(type)>
                    <div class="text-nowrap">
                      Tipo
                    </div>
                  </template>

                  <template #head(subject)>
                    <div class="text-nowrap">
                      Assunto
                    </div>
                  </template>

                  <template #head(shortcut)>
                    <div class="text-nowrap">
                      Atalho
                    </div>
                  </template>

                  <template #head(actions)>
                    <div class="text-nowrap text-center">
                      Ações
                    </div>
                  </template>

                  <template #cell(type)="row">
                    {{ $t(`users.columns.type.${row.item.type}`) }}
                  </template>

                  <template #cell(actions)="row">
                    <div class="text-nowrap text-center">
                      <b-button
                        v-if="$can('read', 'Tools')"
                        variant="flat-primary"
                        :to="{ name: 'tools-email-template-form-update', params: { id: row.item.id } }"
                        title="Editar"
                      >
                        <feather-icon
                          icon="EditIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </b-button>

                      <b-button
                        v-if="$can('read', 'Tools')"
                        variant="flat-primary"
                        title="Remover"
                        @click="deleteTemplate(row.item.id)"
                      >
                        <feather-icon
                          icon="TrashIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </b-button>
                    </div>
                  </template>

                </b-table>
              </b-overlay>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <p>Mostrando {{ pages.from }} até {{ pages.to }} de {{ pages.total }} resultados</p>
            </b-col>

            <b-col cols="6">
              <!-- pagination nav -->
              <b-pagination-nav
                v-model="currentPage"
                :number-of-pages="pages.last_page"
                base-url="#"
                align="end"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import EmailService from '@/services/emailService'
import { formatDateTimeDbToView } from '@/utils/helpers'
import {
  BRow,
  BCol,
  BOverlay,
  BTable,
  BPaginationNav,
  BSpinner,
  BButton,
  BCard,
  BBreadcrumb,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
    BTable,
    BPaginationNav,
    BSpinner,
    BButton,
    BBreadcrumb,
  },
  data() {
    return {
      emailService: null,
      isRemoving: false,
      isLoading: false,
      isSubmited: false,
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Ferramentas',
          active: true,
        },
        {
          text: 'E-mails',
          active: true,
        },
        {
          text: 'Templates',
          active: true,
        },
      ],

      filtersInitialValues: {},
      filtersValues: {
        emails: '',
        type: 'all',
        status: 'all',
        sortBy: 'id',
        sortDesc: true,
      },

      pages: 1,
      currentPage: 1,
      results: [],
      result_fields: [],
      formatDateTimeDbToView,
      preventCloseModal: false,
      dataAssignKanban: null,
    }
  },

  watch: {
    currentPage() {
      this.getTemplates()
    },
  },

  created() {
    this.emailService = new EmailService()
    this.filtersInitialValues = { ...this.filtersValues }

    this.getTemplates()

    this.result_fields = [
      { key: 'id', sortable: false },
      { key: 'subject', sortable: false },
      { key: 'shortcut', sortable: false },
      { key: 'actions', sortable: false },
    ]
  },

  methods: {
    reset() {
      this.filtersValues = JSON.parse(JSON.stringify(this.filtersInitialValues))
      this.results = []
      this.isSubmited = false
    },

    sortingChanged(ctx) {
      this.filtersValues.sortBy = ctx.sortBy
      this.filtersValues.sortDesc = ctx.sortDesc
      this.getUsers()
    },

    getTemplates() {
      this.isLoading = true

      this.emailService.listTemplates().then(response => {
        this.results = response.data.data.templates.sort((a, b) => a.shortcut.localeCompare(b.shortcut))
        this.isLoading = false
      })
    },

    deleteTemplate(id) {
      this.isRemoving = true
      this.$swal({
        title: 'Deletar template?',
        text: 'Esta ação não poderá ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, deletar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.emailService.deleteTemplate(id).then(response => {
            this.isRemoving = false
            if (response.status === 200) {
              this.results = this.results.filter(filter => filter.id !== id)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Template de e-mail ${id} removido!`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  // text: `!`,
                },
              })
            }
          })
        }
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Erro!',
            text: error.response.data.message,
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>
<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';

    .header-card{
        margin-bottom: 20px;
    }

    .form-buttons button{
        margin-right: 10px;
    }
</style>
